<template>
  <!-- Récolte -->
  <form @submit.prevent="onSubmit()">
    <div class="conditions-recolte-subview">
      <div class="tabs-material-subtitle no-padding-bottom">
        <Container>
          <h2>Conditions de récolte</h2>
        </Container>
      </div>

      <Section>
        <Container>
          <h3>Matériel 1</h3>
          <Input id="materielrecolte1" />

          <h3>Matériel 2</h3>
          <Input id="materielrecolte2" />

          <h3>Commentaire</h3>
          <Input id="commentaires" type="textarea" />

          <h3>Conditions de récolte</h3>
          <SelectExtended
            id="conditions_recolte_id"
            optionKey="id"
            optionValue="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
            'conditionsrecolte'
          )}/valeur`"/>
        </Container>
      </Section>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'essaiCalendar', params: {id: $route.params.id} }" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import Btn from '@/components/base/Btn.vue'
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'ConditionsRecolteSubview',
  components: {
    SelectExtended,
    Container,
    Section,
    Input,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      materielrecolte1: this.yup.string().nullable(),
      materielrecolte2: this.yup.string().nullable(),
      commentaires: this.yup.string().nullable(),
      conditions_recolte_id: this.yup.object().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(validationSchema)
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      modal: {
        edit: false,
      },
      modalData: {},
      fiche: {},

      isSubmitting,
      errors,
      onSubmit,
      validationSchema,
    }
  },

  mounted() {
    this.getFiche()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    getFiche() {
      this.emitter.emit('open-loader')

      this.fetchService
        .get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/recolte`)
        .then((response) => {
          const data = response.data[0]
          this.fiche = data

          this.schema = this.formService.populateShema(this.fiche)

          if (this.fiche.conditions_recolte) {
            this.schema.conditions_recolte_id = {
              key: this.fiche.conditions_recolte.id,
              value: this.fiche.conditions_recolte.valeur,
            }
          }

          this.formService.setFormValues(this.schema)

          this.emitter.emit('close-loader')
        })
    },

    handleSubmit(values) {
      // eslint-disable-next-line no-param-reassign
      values.conditions_recolte_id = values?.conditions_recolte_id?.key ?? null

      this.fetchService
        .put(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/recolte/${this.fiche.id}`, values)
        .then((response) => {
          this.fiche = response.data
          this.taskService.handleExperimentalTaskRedirect(this.$route)
        })
      this.modal.edit = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
